import React from 'react';
import { navigate, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Pagination, Typography, Grid, Card, Box, Divider, Hidden } from '@mui/material';

import Layout from '../layout';
import { styles, formatDate } from '../utils';
import CategoryTag from '../components/CategoryTag';
import { CatIcon, DateIcon, ReadIcon } from '../components/Icons';
import Seo from '../components/Seo';

const Blog = props => {
  const { pageContext } = props;
  const { group, pageCount, index } = pageContext;

  const onPageChange = (e, p) => {
    const page = p === 1 ? '' : p;
    navigate(`/${page}`);
  };

  return (
    <Layout showCat={props.location.pathname === '/'}>
      <Seo title='Home' description={process.env.GATSBY_SITE_DESC} image={require('../images/soup.png')} />
      {group.map(post => (
        <Card sx={{ mb: 4 }} elevation={8} key={post.slug}>
          <Box component='article' sx={styles.articleTag}>
            <Link to={`/${post.slug}`} style={{ marginBottom: '10px' }}>
              <Typography variant='h2' sx={styles.archiveTitle}>
                {post.title}
              </Typography>
            </Link>
            <Grid container spacing={2} sx={{ mb: 1, ...styles.flexStartJustify }}>
              <Grid item sx={styles.flexCenter}>
                <DateIcon />
                <Typography variant='caption' sx={{ display: 'inline-block' }}>
                  {formatDate(post.date)}
                </Typography>
              </Grid>
              <Grid item sx={styles.flexStartJustify}>
                <CatIcon />
                <Grid container spacing={1} sx={styles.flexStartJustify}>
                  {post.categories.nodes.map((c, i) => {
                    return <CategoryTag name={c.name} id={c.slug} slug={c.slug} key={c.slug} />;
                  })}
                </Grid>
              </Grid>
              <Grid item sx={styles.flexCenter}>
                <ReadIcon />
                <Typography variant='caption'>{`${post.seo.readingTime} Min Read`}</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%' }} />
            <Grid container spacing={2} sx={styles.flexCenter}>
              <Hidden mdDown>
                <Grid lg={2} item sx={styles.flexCenter}>
                  <GatsbyImage image={getImage(post.featuredImage.node.localFile)} alt={post.title} />
                </Grid>
              </Hidden>
              <Grid lg={10} xs={12} item sx={styles.flexCenter}>
                <Typography
                  component='div'
                  variant='body1'
                  dangerouslySetInnerHTML={{
                    __html: post.excerpt,
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      ))}
      <Box sx={styles.paginationContainer} key='pagination-holder'>
        <Pagination count={pageCount} page={index} shape='rounded' onChange={onPageChange} color='secondary' size='large' variant='outlined' />
      </Box>
    </Layout>
  );
};

export default Blog;
